<script setup lang="ts">
import type { VoixTextFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Two Column Container', group: 'Bonita Containers', layouts: ['Bonita'] },
  slots: [{ name: 'default', allowedElements: ['SlicesBonitaImageTitleCopyCard'] }],
  description: 'Two column container with little teeny-tiny title at the top.',
  preview: 'SlicesBonitaTwoColumnContainer.jpg',
  fields: {
    title: { label: 'Title', type: 'text' },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      title: { value: 'Offers' },
    },
  }],
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
</script>

<template>
  <div class="py-24 px-8">
    <div class="w-full mx-auto grid lg:grid-cols-12 lg:gap-16">
      <div class="lg:col-start-2 lg:col-span-10 grid lg:grid-cols-2 lg:gap-x-16 gap-y-6">
        <div class="lg:col-span-2">
          <h2 class="text-xs uppercase font-sans text-gray-400">
            {{ fields.title.value }}
          </h2>
        </div>
        <slot />
        <div v-if="isVoixTemplatizer " class="h-48 border border-bonita-primary" />
        <div v-if="isVoixTemplatizer " class="h-48 border border-bonita-primary" />
      </div>
    </div>
  </div>
</template>
